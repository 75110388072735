
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,  
} from "@apollo/client";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import Trends2025 from 'views/Trends2025/Trends2025.js'
import Trends2024 from 'views/Trends2024/Trends2024.js'
import LinearRegressionRealWorldApps from 'views/LinearRegressionRealWorldApps/LinearRegressionRealWorldApps.js'
import GenerativeAIFoundation from 'views/GenerativeAIFoundation/GenerativeAIFoundation.js'
import TwelveFactorApp from 'views/12FactorApp/TwelveFactorApp.js';
import DynamicReactComponents from "views/DynamicReactComps/DynamicReactComponents";
import SingleTableAccess from 'views/SingleTableAccess/SingleTableAccess.js';
import Trends2023 from "views/Trends2023Page/Trends2023";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ConfirmationPage from "views/ConfirmationPage/ConfirmationPage";

import ChatGPTApp from "views/ChatGPT/ChatGPT";
import AwsCDK from "views/AwsCDK/AwsCDK";
import Privacy from "views/Privacy/Privacy";

var hist = createBrowserHistory();

const rootElement = document.getElementById("root");

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_ENDPOINT,
  cache: new InMemoryCache()
});

/**
 * Need a suffix of some kind to tell the rule to allow a rewrite. Rule should convert to lowercase and ignore case sensitivity.
 * 
 * suffix === 'article'
 */
ReactDOM.render(  
  <ApolloProvider client = {client}>
      <div>
        <Router history={hist}>
          <Switch>
            {/* Articles */}            
            <Route path="/company/intellipoint-corp/trends2025-article" component={Trends2025} />
            <Route path="/company/intellipoint-corp/trends2024-article" component={Trends2024} />
            <Route path="/company/intellipoint-corp/linear-regression_real_world_apps-article" component={LinearRegressionRealWorldApps} />
            <Route path="/company/intellipoint-corp/generative-ai_foundation-article" component={GenerativeAIFoundation} />            
            <Route path="/company/intellipoint-corp/applying-12-factor-app-principles-article" component={TwelveFactorApp} />
            <Route path="/company/intellipoint-corp/single-table-access-article" component={SingleTableAccess} />
            <Route path="/company/intellipoint-corp/trending-in-2023-article" component={Trends2023} />
            <Route path="/company/intellipoint-corp/creating-dynamic-react-components-article" component={DynamicReactComponents} />
            <Route path="/company/intellipoint-corp/chatGPT-article" component={ChatGPTApp} />
            <Route path="/company/intellipoint-corp/aws-cdk-article" component={AwsCDK} />

            {/* Pages */}
            <Route path="/company/intellipoint-corporation/privacy-page" component={Privacy} />
            <Route path="/company/intellipoint-corporation/about-us-page" component={AboutUsPage} />
            <Route path="/company/intellipoint-corporation/contact-us-page" component={ContactUsPage} />
            <Route path="/company/intellipoint-corporation/confirmation-page" component={ConfirmationPage} />
            <Route path="/home-page" component={LandingPage} />
            <Route path="/" component={LandingPage} />
          </Switch>
        </Router>
      </div>
    </ApolloProvider>,  
    rootElement  
);
