import { v4 as uuidv4 } from 'uuid';

export const data = { 
    "brand": "Intellipoint Corporation",
    "backdrop": "/img/bg8.webp",
    "slogan": "Our Talking Points",
    "topics": "Latest Insights",
    "headlines": [
        `We support business-critical transformation within the world’s leading hospitality, education, energy and financial markets.`,
        `We enable our partners, clients and colleagues to meaningfully differentiate themselves and successfully navigate a shifting landscape.`,
        `We help our partners generate long-term value and help deliver an experience that improves customer engagement, traffic and revenue at lower costs.`
    ],
    "product": {
        "slogan": "Positioning Business For Success",
        "headline": "In an ever-changing world of technologies, trends and systems, businesses are faced with making critical decisions with minimal time, here's where we can help:",
        "insights": {
            "title": "Specialization",    
            "bullets": [
                "Digital Transformation and Modernization",
                "Cloud Solution Architecture",
                "System Design",                
                "Full-Stack Application Development",                                
            ]
        },
        "industries": {
            "title": "Industries",
            "bullets": [
                "Hospitality, Travel and Leisure",
                "Higher Education and Standardized Testing",
                "Industrial and Renewable Energy",
                "Financial Accounting and Investment"
            ]
        },
        "strategies": {
            "title": "Process",
            "bullets": [
                `Adapt to Rapid Changes with Agile`,
                `Automate Repeatable Processes`,
                `Create cost-effective, scalable solutions`,               
                `Define purpose-driven process`
            ]
        },
        "author": {
            "avatar": "img/avatar2.webp",
            "avatar2": "img/avatar.webp",
            "facebook": "https://www.facebook.com/archAngel1963/",
            "twitter": "https://twitter.com/mvargas2015",
            "linkedIn": "https://www.linkedin.com/in/agentvargas2012r/",
            "name": "Michael Vargas",
            "title": "Founder of Intellipoint Corporation",
            "description": "We're dedicated to the success of your vision, leadership and business. "
        },
        "useCaseStudies": {
            "title": "Intellipoint Corporation: Tech Discussions",
            "cards": [{
                "id": uuidv4(), 
                "backdrop": "/img/bg5.jpg",
                "category": "Technologies",
                "cta": "Read Article",
                "title": "Generative AI Foundation",
                "description": `This takes a holistic view of getting into the Generative AI Field.
                In this introduction discussion, we consider the underlying factors and principles 
                that are combined for Generative AI. Who better to explain AI than itself, right?`,
                "href": "/company/intellipoint-corp/generative-ai_foundation-article",
            },
            {
                "id": uuidv4(), 
                "backdrop": "/img/bg1.webp",
                "category": "Technologies",
                "cta": "Read Article",
                "title": "IT Trends in 2025",
                "description": `Let's take a look at the dominant trends in 2024 IT.
                As you might expect, some of the previous technology trends return but we have a new
                few key players that are quickly taking shape. 
                Let's look at the data points.`,
                "href": "/company/intellipoint-corp/trends2025-article",
            },
            {
                "id": uuidv4(), 
                "backdrop": "/img/bg2.jpg",
                "category": "Technologies",
                "cta": "Read Article",
                "title": "Apps of Linear Regression",
                "description": `In this article, we take a look at some real-world applications of using linear regression modeling to give some context
                around the vauge term and meaning. AI discusses this in futher detail here.`,
                "href": "/company/intellipoint-corp/linear-regression_real_world_apps-article",
            }],
            "moreStudies": [{
                "category": "System Design",
                "backdrop": "/img/bg6.jpg",
                "placeholder": "/img/bg6-lr.jpg",
                "title": "Designing Single Table Access Patterns",
                "alt": "Designing Single Table Access Patterns",
                "intro": `In this article, we'll take a closer look into designing access patterns using the single table strategy. This has been a pretty 
                hot topic in the industry over the last few years because it positions your application to use NoSQL tables in a way that promotes cost-effective savings and fast read/write access.`,
                "learnMore": "/company/intellipoint-corp/single-table-access-article",
                "lmDesc": "Read More"
            }, 
            {
                "category": "Architecture",
                "backdrop": "/img/bg7.jpg",
                "placeholder": "/img/bg3-lr.jpg",
                "title": "Architecting 12 Factor Apps",
                "alt": "Architecting 12 Factor Apps",
                "intro": "We\’ll apply the 12 factor app methodology to our serverless app running on AWS. By following this strategy, we\’ll produce a powerful, robust system that will meet all the criteria required for designing modern applications that run on the cloud using industry best practices and system-design principles...",
                "learnMore": "/company/intellipoint-corp/applying-12-factor-app-principles-article",
                "lmDesc": "Read More"
            },
            {
                "category": "Development",
                "backdrop": "/img/bg8.jpg",
                "placeholder": "/img/bg5-lr.jpg",
                "title": "Creating Dynamic React Components",
                "alt": "Creating Dynamic React Components",
                "intro": "Let\'s take a closer look into developing React components that load data dynamically. This will allow us to follow the principles of DRY and create components which we can reuse across the application in a manner that is a low-code effort. Let\'s get started with this...",
                "learnMore": "/company/intellipoint-corp/creating-dynamic-react-components-article",
                "lmDesc": "Read More"
            }]    
        }        
    }

};